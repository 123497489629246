import P from '../../package.json'
import React from 'react'

const Footer = ({ dispatch }) => {
  return (
    <footer className="container mx-auto flex flex-row gap-2 text-gray-500 items-center text-sm p-3 md:p-4 font-semibold">
      <p className="text-center sm:text-left">&#169; 2021 KNIT Finance</p>
      <p>/</p>
      <p
        onClick={() =>
          dispatch({
            type: 'TOUR_OPEN'
          })
        }
        className="hover:text-blue-500 cursor-pointer">
        Help
      </p>
      <a
        href="http://docs.knit.finance/"
        target="_blank"
        rel="noreferrer"
        className="hover:text-blue-500">
        Docs
      </a>
      <a
        href="http://status.knit.finance/"
        target="_blank"
        rel="noreferrer"
        className="hover:text-blue-500">
        Status
      </a>
      <p className="text-gray-500">
        {`Build: ${P.version || process.env.REACT_APP_GIT_SHA || 'v0.1.1'}`}
      </p>
    </footer>
  )
}

export default Footer
