import { contracts, custodyWallet, stakingContract } from './config'

import { networks as availableNetowrks } from './constant'

require('dotenv').config()
const contract = require('@truffle/contract')
const abi = require('./abi/abi.json')

export const availableNonEvmNetworks = [
  {
    title: 'Bitcoin',
    subtitle: 'BTC',
    image: 'BTC.png',
    disabled: false
  },
  {
    title: 'Litecoin',
    subtitle: 'LTC',
    image: 'LTC.png',
    disabled: false
  },
  {
    title: 'Stellar',
    subtitle: 'XLM',
    image: 'XLM.png',
    disabled: false
  },
  {
    title: 'Dash',
    subtitle: 'DASH',
    image: 'DASH.png',
    disabled: false
  },
  {
    title: 'Ripple',
    subtitle: 'XRP',
    image: 'XRP.png',
    disabled: false
  },
  {
    title: 'Bitcoin Cash',
    subtitle: 'BCH',
    image: 'BCH.png',
    disabled: false
  },
  {
    title: 'Bitcoin SV',
    subtitle: 'BSV',
    image: 'BSV.png',
    disabled: false
  },
  {
    title: 'Zcash',
    subtitle: 'ZEC',
    image: 'ZEC.png',
    disabled: false
  }
]

export const networkCoins = [
  {
    label: 'Bitcoin',
    knitName: 'BTC',
    network: 'BTC',
    symbol: 'BTC',
    evm: false
  },
  {
    label: 'Ripple',
    knitName: 'XRP',
    network: 'XRP',
    symbol: 'XRP',
    evm: false
  },
  {
    label: 'Litecoin',
    network: 'LTC',
    knitName: 'LTC',
    symbol: 'LTC',
    evm: false
  },
  {
    label: 'Stellar',
    network: 'XLM',
    knitName: 'XLM',
    symbol: 'XLM',
    evm: false
  },
  {
    label: 'Ethereum',
    symbol: 'ETH',
    knitName: 'ETH',
    network: 'ETH',
    evm: true
  },
  {
    label: 'Polygon',
    symbol: 'MATIC',
    knitName: 'MATIC',
    network: 'MATIC',
    evm: true
  },
  {
    label: 'Dash',
    symbol: 'DASH',
    knitName: 'DASH',
    network: 'DASH',
    evm: false
  },
  {
    label: 'Fantom',
    symbol: 'FTM',
    knitName: 'FTM',
    network: 'FTM',
    evm: true
  },
  {
    label: 'Bitcoin Cash',
    symbol: 'BCH',
    knitName: 'BCH',
    network: 'BCH',
    evm: false
  },
  {
    label: 'Bitcoin SV',
    symbol: 'BSV',
    knitName: 'BSV',
    network: 'BSV',
    evm: false
  },
  {
    label: 'Zcash',
    symbol: 'ZEC',
    knitName: 'ZEC',
    network: 'ZEC',
    evm: false
  }
  // {
  //   label: 'Bitrise Chain',
  //   knitName: 'kBRISE',
  //   symbol: 'BRISE',
  //   network: 'BRISE',
  //   evm: true,
  //   disable: true
  // }
]
export const availableCoins = [
  // {
  //   label: 'Brise',
  //   knitName: 'kBRISE',
  //   symbol: 'BRISE',
  //   evm: true,
  //   disabled: false,
  //   custody: []
  // },
  {
    label: 'Ethereum',
    knitName: 'kETH',
    symbol: 'ETH',
    disabled: false,
    evm: true,
    custody: []
  },
  {
    label: 'Bitcoin',
    knitName: 'kBTC',
    symbol: 'BTC',
    evm: false,
    disabled: false,
    custody: []
  },
  {
    label: 'Litecoin',
    knitName: 'kLTC',
    symbol: 'LTC',
    evm: false,
    disabled: false,
    custody: []
  },
  {
    label: 'Stellar',
    knitName: 'kXLM',
    symbol: 'XLM',
    evm: false,
    disabled: false,
    custody: []
  },
  {
    label: 'Bitcoin Cash',
    knitName: 'kBCH',
    symbol: 'BCH',
    evm: false,
    disabled: false,
    custody: []
  },
  {
    label: 'Bitcoin SV',
    knitName: 'kBSV',
    symbol: 'BSV',
    evm: false,
    disabled: false,
    custody: []
  },
  {
    label: 'Dash',
    knitName: 'kDASH',
    symbol: 'DASH',
    evm: false,
    disabled: false,
    custody: []
  },
  {
    label: 'Dogecoin',
    knitName: 'kDOGE',
    symbol: 'DOGE',
    evm: true,
    disabled: false,
    custody: []
  },
  {
    label: 'Frontier',
    knitName: 'kFRONT',
    symbol: 'FRONT',
    evm: true,
    disabled: false,
    custody: []
  },

  {
    label: 'Ripple',
    knitName: 'kXRP',
    symbol: 'XRP',
    evm: false,
    disabled: false,
    custody: []
  },
  {
    label: 'Zcash',
    knitName: 'kZEC',
    symbol: 'ZEC',
    evm: false,
    disabled: false,
    custody: []
  },
  {
    label: 'Avalanche',
    knitName: 'kAVAX',
    symbol: 'AVAX',
    evm: true,
    disabled: false,
    custody: []
  },
  {
    label: 'Fantom',
    knitName: 'kFTM',
    symbol: 'FTM',
    evm: true,
    disabled: false,
    custody: []
  },
  // {
  //   label: 'Heco',
  //   knitName: 'kHT',
  //   symbol: 'HT',
  //   evm: true,
  //   disabled: true,
  //   custody: []
  // },
  {
    label: 'Gemini dollar',
    knitName: 'kGUSD',
    symbol: 'GUSD',
    evm: true,
    disabled: false,
    custody: []
  },
  {
    label: 'Origin Protocol',
    knitName: 'kOGN',
    symbol: 'OGN',
    evm: true,
    disabled: false,
    custody: []
  },
  {
    label: 'KyberNetwork',
    knitName: 'kKNC',
    symbol: 'KNC',
    evm: true,
    disabled: false,
    custody: []
  },
  {
    label: 'Polygon MATIC',
    knitName: 'kMATIC',
    symbol: 'MATIC',
    evm: true,
    disabled: false,
    custody: []
  }
]

export const swapCoins = [
  {
    label: 'Bitrise Token',
    custodyName: 'BRISE',
    knitName: 'kBRISE',
    symbol: 'BRISE',
    disabled: false,
    custody: ['BSC'],
    decimals: 9
  },
  {
    label: 'Knit Finance',
    knitName: 'KFT',
    custodyName: 'KFT',
    symbol: 'KFT',
    disabled: false,
    custody: ['ETH'],
    decimals: 18
  },
  {
    label: 'USDT',
    knitName: 'USDk',
    custodyName: 'USDT',
    symbol: 'USDT',
    disabled: false,
    custody: ['BSC'],
    decimals: 18
  }
]

export const stakeCoin = [
  {
    label: 'Testing Token',
    knitName: 'TKFT',
    symbol: 'TKFT',
    address: '0xb8Ff6E828353489fa405A0134D4Dcf8d4E652740',
    disabled: false
  }
]

export const ethCoins = ['ETH']
export const getNetwork = chainId => {
  let selectedNetwork = availableNetowrks.filter(
    values => values.chainId === chainId && values.disabled === false
  )
  return selectedNetwork.length > 0 ? selectedNetwork[0] : null
}

export const truncate = (str, max, sep) => {
  str = typeof str !== 'undefined' && str !== null ? str : ''
  max = max || 6

  var len = str.length
  if (len > max) {
    sep = sep || '...'
    var seplen = sep.length
    if (seplen > max) {
      return str.substr(len - max)
    }
    var n = -0.5 * (max - len - seplen)
    var center = len / 2

    var front = str.substr(0, center - n)
    var back = str.substr(len - center + n)
    return front + sep + back
  }

  return str
}

export const getMetamaskObj = network => {
  let returnObject = {},
    method = 'wallet_addEthereumChain'

  const chainId = parseInt(network?.chainId)
  switch (chainId) {
    case 1:
      returnObject = {
        chainId: `0x${chainId.toString(16)}`
      }

      method = 'wallet_switchEthereumChain'

      break
    case 56:
      returnObject = {
        chainId: `0x${chainId.toString(16)}`,
        chainName: 'Binance Smart Chain Mainnet',
        nativeCurrency: {
          name: 'BNB',
          symbol: 'bnb',
          decimals: 18
        },
        rpcUrls: [network.rpc],
        blockExplorerUrls: [`https://bscscan.com`]
      }

      break
    case 32520:
      returnObject = {
        chainId: `0x${chainId.toString(16)}`,
        chainName: 'Brise Chain Mainnet',
        nativeCurrency: {
          name: 'BRISE',
          symbol: 'BRISE',
          decimals: 18
        },
        rpcUrls: [network.rpc],
        blockExplorerUrls: [`https://brisescan.com`]
      }

      break
    case 137:
      returnObject = {
        chainId: `0x${chainId.toString(16)}`,
        chainName: 'Matic',
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18
        },
        rpcUrls: [network.rpc],
        blockExplorerUrls: [`https://polygonscan.com`]
      }

      break

    case 250:
      returnObject = {
        chainId: `0x${chainId.toString(16)}`,
        chainName: 'Fantom',
        nativeCurrency: {
          name: 'FTM',
          symbol: 'FTM',
          decimals: 18
        },
        rpcUrls: [network.rpc],
        blockExplorerUrls: [`https://ftmscan.com/`]
      }

      break

    case 128:
      returnObject = {
        chainId: `0x${chainId.toString(16)}`,
        chainName: 'Huobi ECO Chain Mainnet',
        nativeCurrency: {
          name: 'HT',
          symbol: 'HT',
          decimals: 18
        },
        rpcUrls: [network.rpc],
        blockExplorerUrls: [`https://hecoinfo.com/`]
      }

      break

    case 256:
      returnObject = {
        chainId: `0x${chainId.toString(16)}`,
        chainName: 'Moonbeam Alpha',
        nativeCurrency: {
          name: 'HT',
          symbol: 'HT',
          decimals: 18
        },
        rpcUrls: [network.rpc],
        blockExplorerUrls: [`https://testnet.bscscan.com`]
      }

      break

    default:
  }
  return { obj: [returnObject], method }
}

export const setupNetwork = async network => {
  const provider = window.ethereum

  const { obj, method } = getMetamaskObj(network)

  if (provider) {
    try {
      await provider.request({
        method: method,
        params: obj
      })

      return true
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error)
      return false
    }
  } else {
    console.error(
      "Can't setup the BSC network on metamask because window.ethereum is undefined"
    )
    return false
  }
}

export const addToMetamask = async (address, coin) => {
  const provider = window.ethereum
  return await provider.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20', // Initially only supports ERC20, but eventually more!
      options: {
        address: address, // The address that the token is at.
        decimals: 18,
        symbol: `k${coin}`
      }
    }
  })
}

export const sendEth = async (web3, to, from, value) =>
  await web3.eth.sendTransaction({
    from,
    to,
    value
  })

export const requestHandle = promise => {
  return promise
    .then(data => [data, undefined])
    .catch(error => Promise.resolve([undefined, error]))
}

export const filterAvailable = network => {
  const returnObj = {}

  Object.keys(contracts).forEach(value => {
    returnObj[value] = contracts[value][network] !== ''
  })
  return returnObj
}

export const filterCoins = (allCoins, network) => {
  const allAvailble = filterAvailable(network)
  return allCoins.filter(value => allAvailble[value.symbol])
}

export const filterAvailableCoins = (allCoins, network) => {
  const allAvailble = filterAvailable(network)
  return allCoins.filter(value => allAvailble[value.symbol])
}

export const wrapProcessHandler = async (web3, account, hdWallet, amount) => {
  try {
    const result = await sendEth(
      web3,
      hdWallet,
      account,
      web3.utils.toWei(amount.toString(), 'ether')
    )

    return result?.transactionHash || result?.tx
  } catch (e) {
    throw e
  }
}

export const unwrapProcessHandler = async (
  web3,
  account,
  asset,
  amount,
  network,
  networkTo
) => {
  try {
    let contractInstance = contract({
      abi: abi
    })

    contractInstance.setProvider(web3.currentProvider)
    const tokenInstant = await contractInstance.at(
      contracts[asset.symbol][network.subtitle]
    )
    //isCustody = asset.custody.includes(networkTo?.subtitle)

    // if (isCustody) {
    //   const decimals = asset.decimals ? asset.decimals : 18
    //   amount = web3.utils.toWei(amount, etherUtils[decimals])
    // } else {
    //}

    amount = web3.utils.toWei(amount, 'ether')
    const result = await tokenInstant.burn(amount, {
      from: account
    })

    return result?.transactionHash || result?.tx
  } catch (e) {
    throw e
  }
}

export const swapProcessHandler = async (
  web3,
  account,
  asset,
  amount,
  network
) => {
  try {
    let contractInstance = contract({
        abi: abi
      }),
      result
    contractInstance.setProvider(web3.currentProvider)
    const tokenInstant = await contractInstance.at(
        contracts[asset.symbol][network.subtitle]
      ),
      isCustody = asset.custody.includes(network?.subtitle)

    if (isCustody) {
      const decimals = asset.decimals ? asset.decimals : 18
      amount = web3.utils.toWei(amount, etherUtils[decimals])
      result = await tokenInstant.transfer(custodyWallet, amount, {
        from: account
      })
    } else {
      amount = web3.utils.toWei(amount, 'ether')
      result = await tokenInstant.burn(amount, {
        from: account
      })
    }

    return {
      tokenAddress: contracts[asset.symbol][network.subtitle],
      txnhash: result?.transactionHash || result?.tx
    }
  } catch (e) {
    throw e
  }
}

export const getbalance = async (web3, asset, network, account) => {
  let bal = 0
  //  if (!contracts[asset?.symbol][network?.subtitle]) return bal
  try {
    const contractInstance = contract({
      abi: abi
    })
    if (
      web3 !== null &&
      typeof contracts[asset.symbol][network?.subtitle] !== 'undefined'
    ) {
      contractInstance.setProvider(web3?.currentProvider)

      const tokenInstant = await contractInstance.at(
        contracts[asset.symbol][network?.subtitle]
      )
      bal = await tokenInstant.balanceOf(account)
    }
  } catch (e) {
    console.log(e)
  }
  return bal
}

export const getAssetName = currency => {
  const mainAssets = ['KFT']

  return mainAssets.includes(currency) ? currency : `k${currency}`
}

export const calculateAPR = (amount, month, totalStake) => {
  if (totalStake.toString() === '0') {
    totalStake = 1
  }

  // const perTokenReward = new BigNumber(totalStake).div(
  //   stakingContract.tokenPerBlock
  // )
  // const amountInvest = new BigNumber(amount).div(10 ** 18)
  //
  // const amountReward = perTokenReward.times(amountInvest)
  // const daysAmountReward = amountReward
  //   .times(stakingContract.blockPerDay)
  //   .times(month)
  //
  // const rewardForThePeroid = daysAmountReward.minus(amountInvest)

  const totalRewards =
    (stakingContract.blockPerYear * stakingContract.tokenPerBlock) / 100
  //console.log(totalStake / totalRewards / 1e18)

  let interestRate = totalStake / totalRewards / 1e18

  return ((amount * month * interestRate) / 100) * (1 / 365)
}

export const etherUtils = {
  18: 'ether',
  9: 'gwei'
}
