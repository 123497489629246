import { truncate, getNetwork } from '../util'
import { useDispatch, useSelector } from 'react-redux'

import { Link } from 'react-router-dom'
import React, { useCallback, useEffect } from 'react'
import Web3 from 'web3'
import { useWalletConnectProvider } from '../hooks/walletConnect'

const ConnectWallet = () => {
  const [provider, loading, connected] = useWalletConnectProvider()
  const dispatch = useDispatch()
  const [account, network] = useSelector(state => [
    state.connectWallet.account,
    state.connectWallet.network
  ])

  const handlerConnect = useCallback(async () => {
    try {
      const providerInstance = await provider.connect()

      providerInstance.on('accountsChanged', accountChangeCallback)
      providerInstance.on('chainChanged', chainChangeCallback)

      const web3Provider = new Web3(providerInstance)
      const account = await web3Provider.eth.getAccounts()
      const chainId = await web3Provider.eth.getChainId()

      dispatch({
        type: 'CONNECT_WALLET',
        data: {
          providerInstance,
          web3Provider,
          account: account[0]
        }
      })

      handleNetworkChange(chainId)
    } catch (e) {
      console.log(e)
    }
  }, [provider]) // eslint-disable-line react-hooks/exhaustive-deps

  const accountChangeCallback = (accounts: string[]) => {
    dispatch({
      type: 'ACCOUNT_CHANGE',
      data: { account: accounts[0] }
    })
  }

  const chainChangeCallback = (chainId: number) => {
    const newChainID = parseInt(chainId, 16)
    handleNetworkChange(newChainID)
  }

  const handleNetworkChange = chainId => {
    const currentNetwork = getNetwork(chainId)

    dispatch({
      type: 'NETWORK_CHANGE',
      data: {
        network: currentNetwork,
        chainId,
        isNetworkConnected: currentNetwork !== null
      }
    })
  }
  useEffect(() => {
    if (!loading && connected) {
      handlerConnect()
    }
  }, [handlerConnect, loading, connected])

  if (!account) {
    return (
      <button
        className="border-0 py-1 px-3 focus:outline-none rounded-lg text-white md:mt-0 bg-blue-700 hover:bg-blue-800 step-1"
        onClick={handlerConnect}>
        Connect Wallet
      </button>
    )
  }

  return (
    <div className="flex space-x-3 items-center step-1">
      <div className="border-2 px-3 py-1 rounded-lg border-blue-700">
        {network && network.title ? (
          <p className="flex space-x-3 text-gray-200 font-semibold">
            <p>{network.title}</p>
          </p>
        ) : (
          <p className="text-red-600 font-semibold">Network not support</p>
        )}
      </div>

      <Link to="/user/dashboard">
        <div className="border-2 px-3 py-1 rounded-lg border-blue-700">
          <p className="font-semibold cursor-pointer text-white">
            {truncate(account, 14)}
          </p>
        </div>
      </Link>
    </div>
  )
}
export default ConnectWallet
