import ConnectWallet from './ConnectWallet'
import { NavLink } from 'react-router-dom'
import React from 'react'

const Menu = () => {
  return (
    <div className="text-gray-400 body-font">
      <div className="container mx-auto flex flex-wrap p-3 md:p-5 flex-row md:flex-row items-center justify-between md:justify-center">
        <a
          className="flex font-medium items-center text-white hidden md:block cursor-pointer"
          href="/">
          <img src="/img/logo.svg" alt="knit logo" className="w-40" />
        </a>

        <a className="flex text-white md:mb-0 block md:hidden" href="/">
          <img src="/img/logo-small.svg" alt="knit logo" className="w-8" />
        </a>

        <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700 flex flex-wrap items-center text-base justify-center nav hidden md:block">
          <NavLink to="/" strict={true} exact={true} activeClassName="selected">
            Bridge
          </NavLink>
          <NavLink
            to="/staking"
            strict={true}
            exact={true}
            activeClassName="selected">
            Staking
          </NavLink>
          <NavLink
            to="/lp"
            strict={true}
            exact={true}
            activeClassName="selected">
            Liquidity Pool
          </NavLink>
        </nav>

        <ConnectWallet />
      </div>
    </div>
  )
}

export default Menu
