export const networks = [
  {
    title: 'Binance',
    subtitle: 'BSC',
    image: 'BSC.png',
    chainId: 56,
    disabled: false,
    rpc: `https://bsc-dataseed.binance.org/`,
    baseCoin: 'BNB'
  },
  {
    title: 'Bitgert Chain',
    subtitle: 'BRISE',
    image: 'BRISE.png',
    chainId: 32520,
    disabled: false,
    rpc: 'https://mainnet-rpc.brisescan.com',
    baseCoin: 'BRISE'
  },
  {
    title: 'Ethereum',
    subtitle: 'ETH',
    image: 'Ethereum.png',
    chainId: 1,
    disabled: false,
    rpc: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
    baseCoin: 'ETH'
  },
  {
    title: 'Polygon',
    subtitle: 'MATIC',
    image: 'Matic.png',
    chainId: 137,
    disabled: false,
    rpc: `https://rpc-mainnet.maticvigil.com/`,
    baseCoin: 'MATIC'
  },

  {
    title: 'Fantom',
    subtitle: 'FANTOM',
    image: 'Fantom.png',
    chainId: 250,
    disabled: false,
    rpc: 'https://rpc.ftm.tools',
    baseCoin: 'FTM'
  },
  {
    title: 'Huobi ECO Chain',
    subtitle: 'HECO',
    image: 'Heco.png',
    chainId: 128,
    disabled: false,
    rpc: 'https://http-mainnet.hecochain.com',
    baseCoin: 'HT'
  }
]

export const otherNetworks = [
  {
    title: 'Bitcoin',
    subtitle: 'BTC',
    image: 'BTC.png',
    chainId: 0,
    disabled: false,
    rpc: null,
    baseCoin: 'BTC'
  },
  {
    title: 'Litecoin',
    subtitle: 'LTC',
    image: 'LTC.png',
    chainId: 0,
    disabled: false,
    rpc: null,
    baseCoin: 'LTC'
  },
  {
    title: 'Stellar',
    subtitle: 'XLM',
    image: 'XLM.png',
    chainId: 0,
    disabled: false,
    rpc: null,
    baseCoin: 'XLM'
  },
  {
    title: 'Dash',
    subtitle: 'DASH',
    image: 'DASH.png',
    chainId: 0,
    disabled: false,
    rpc: null,
    baseCoin: 'DASH'
  },
  {
    title: 'Ripple',
    subtitle: 'XRP',
    image: 'XRP.png',
    chainId: 0,
    disabled: false,
    rpc: null,
    baseCoin: 'XRP'
  }
]

export const kAssets = [
  {
    label: 'Ethereum',
    knitName: 'kETH',
    symbol: 'ETH',
    disabled: false,
    evm: true,
    kAsset: true,
    custody: []
  },
  {
    label: 'Polygon MATIC',
    knitName: 'kMATIC',
    symbol: 'MATIC_POLYGON',
    evm: true,
    kAsset: true,
    disabled: false,
    custody: []
  },

  // {
  //   label: 'Avalanche',
  //   knitName: 'kAVAX',
  //   symbol: 'AVAX',
  //   evm: true,
  //   disabled: true,
  //   custody: []
  // },
  {
    label: 'Fantom',
    knitName: 'kFTM',
    symbol: 'FTM',
    evm: true,
    kAsset: true,
    disabled: false,
    custody: []
  },
  {
    label: 'Bitcoin',
    knitName: 'kBTC',
    symbol: 'BTC',
    evm: false,
    kAsset: true,
    disabled: false,
    custody: []
  },
  {
    label: 'Litecoin',
    knitName: 'kLTC',
    symbol: 'LTC',
    evm: false,
    kAsset: true,
    disabled: false,
    custody: []
  },
  {
    label: 'Stellar',
    knitName: 'kXLM',
    symbol: 'XLM',
    evm: false,
    kAsset: true,
    disabled: false,
    custody: []
  },
  {
    label: 'Bitcoin Cash',
    knitName: 'kBCH',
    symbol: 'BCH',
    evm: false,
    kAsset: true,
    disabled: false,
    custody: []
  },
  {
    label: 'Bitcoin SV',
    knitName: 'kBSV',
    symbol: 'BSV',
    evm: false,
    kAsset: true,
    disabled: false,
    custody: []
  },
  {
    label: 'Dash',
    knitName: 'kDASH',
    symbol: 'DASH',
    evm: false,
    kAsset: true,
    disabled: false,
    custody: []
  },
  {
    label: 'Ripple',
    knitName: 'kXRP',
    symbol: 'XRP',
    evm: false,
    kAsset: true,
    disabled: false,
    custody: []
  },
  {
    label: 'Zcash',
    knitName: 'kZEC',
    symbol: 'ZEC',
    evm: false,
    kAsset: true,
    disabled: false,
    custody: []
  },
  {
    label: 'Heco',
    knitName: 'kHT',
    symbol: 'HT',
    evm: true,
    disabled: true,
    custody: []
  },
  {
    label: 'Gemini dollar',
    knitName: 'kGUSD',
    symbol: 'GUSD',
    evm: true,
    disabled: true,
    custody: []
  },
  {
    label: 'Origin Protocol',
    knitName: 'kOGN',
    symbol: 'OGN',
    evm: true,
    disabled: true,
    custody: []
  },
  {
    label: 'KyberNetwork',
    knitName: 'kKNC',
    symbol: 'KNC',
    evm: true,
    disabled: true,
    custody: []
  },
  {
    label: 'Dogecoin',
    knitName: 'kDOGE',
    symbol: 'DOGE',
    evm: true,
    disabled: false,
    custody: []
  },
  {
    label: 'Frontier',
    knitName: 'kFRONT',
    symbol: 'FRONT',
    evm: true,
    disabled: true,
    custody: []
  }
]

export const nativeAssets = [
  {
    label: 'Ethereum',
    knitName: 'ETH',
    symbol: 'ETH',
    disabled: false,
    evm: true,
    kAsset: false,
    custody: []
  },
  {
    label: 'Polygon MATIC',
    knitName: 'MATIC',
    symbol: 'MATIC_POLYGON',
    evm: true,
    disabled: false,
    kAsset: false,
    custody: []
  },
  {
    label: 'Fantom',
    knitName: 'FTM',
    symbol: 'FTM',
    evm: true,
    disabled: false,
    kAsset: false,
    custody: []
  },
  {
    label: 'Brise',
    knitName: 'BRISE',
    symbol: 'BRISE',
    evm: true,
    disabled: false,
    kAsset: false,
    custody: []
  }
]
