const initialState = {
  tour: true,
  evm: 0
}
const general = (state = initialState, action) => {
  switch (action.type) {
    case 'TOUR_CLOSE':
      return {
        ...state,
        tour: false
      }
    case 'TOUR_OPEN':
      return {
        ...state,
        tour: true
      }
    case 'EVM_SWITCH':
      return {
        ...state,
        evm: state.evm === 1 ? 0 : 1
      }
    case 'STAKE_UNSTAKE':
      return {
        ...state,
        stakeunstake: state.stakeunstake === 1 ? 0 : 1
      }
    default:
      return state
  }
}

export default general
