import React, { Suspense } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import Footer from './components/Footer'
import Loading from './components/Loading'
import Menu from './components/Menu'
import MobileMenu from './components/MobileMenu'
import { useDispatch } from 'react-redux'

//import Tour from 'reactour'

// const modelbg = {
//   style: {
//     backgroundColor: '#111827'
//   }
// }
// const steps = [
//   {
//     selector: '.step-1',
//     content: ({ goTo, inDOM }) => (
//       <p className="text-white">
//         You can connect your crypto-wallet using this button. Use Metamask or
//         WalletConnect
//       </p>
//     ),
//     ...modelbg
//   },
//   {
//     selector: '.step-2',
//     content: ({ goTo, inDOM }) => (
//       <p className="text-white">
//         Now choose the network with which you want to do the transaction.
//       </p>
//     ),
//     ...modelbg
//   },
//   {
//     selector: '.step-3',
//     content: ({ goTo, inDOM }) => (
//       <p className="text-white">Enter the amount you want to wrap</p>
//     ),
//     ...modelbg
//   },
//   {
//     selector: '.step-4',
//     content: ({ goTo, inDOM }) => (
//       <p className="text-white">Enter the asset you want to wrap</p>
//     ),
//     ...modelbg
//   },
//   {
//     selector: '.step-5',
//     content: ({ goTo, inDOM }) => (
//       <p className="text-white">
//         Enter the wallet address from where you are sending the original asset.
//       </p>
//     ),
//     ...modelbg
//   },
//   {
//     selector: '.step-6',
//     content: ({ goTo, inDOM }) => (
//       <p className="text-white">
//         If everything is right, this should be automatically filled in. If not
//         try reconnecting your wallet.
//       </p>
//     ),
//     ...modelbg
//   }
// ]

//const Wrap = React.lazy(() => import('./components/Wrapv1'))
//const SwapVerify = React.lazy(() => import('./components/Wrapv1/SwapVerify'))
//const UnWrap = React.lazy(() => import('./components/UnWrap'))
//const Exchange = React.lazy(() => import('./components/Exchange'))
const OneUi = React.lazy(() => import('./components/OneUi'))
//const Feedback = React.lazy(() => import('./components/Feedback'))
const Comingsoon = React.lazy(() => import('./components/Comingsoon'))
const Dashboard = React.lazy(() => import('./components/Dashboard'))
const Staking = React.lazy(() => import('./components/Staking'))
//const PublicDashboard = React.lazy(() => import('./components/PublicDashboard'))
const TranDetails = React.lazy(() => import('./components/OneUi/TranDetails'))

function App() {
  const dispatch = useDispatch()
  //const isTourOpen = useSelector(state => state.general.tour)

  return (
    <div
      className="bg-no-repeat bg-center bg-cover thepage"
      style={{
        backgroundImage: `url("/img/bg.png")`
      }}>
      <Router>
        <header>
          <Menu />
          <MobileMenu />
        </header>
        <Suspense fallback={<Loading />}>
          <main className="flex flex-row items-center justify-center">
            <Switch>
              <Route exact path="/">
                <OneUi />
              </Route>
              <Route exact path="/staking">
                <Staking />
              </Route>
              <Route exact path="/lp">
                <Comingsoon />
              </Route>

              <Route exact path="/tx/:action/:txnhash">
                <TranDetails />
              </Route>
              <Route exact path="/user/dashboard">
                <Dashboard />
              </Route>
            </Switch>
          </main>
        </Suspense>
        <Footer dispatch={dispatch} />
      </Router>
    </div>
  )
}

export default App
