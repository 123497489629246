const initialState = {
  provider: null,
  web3: null,
  account: null,
  chainId: null,
  network: null,
  isNetworkConnected: false
}
const connectWallet = (state = initialState, action) => {
  switch (action.type) {
    case 'CONNECT_WALLET':
      return {
        ...state,
        provider: action.data.providerInstance,
        web3: action.data.web3Provider,
        account: action.data.account,
        chainId: action.data.chainId,
        network: action.data.network,
        isNetworkConnected: action.data.isConnected
      }
    case 'ACCOUNT_CHANGE':
      return {
        ...state,
        account: action.data.account
      }
    case 'CHAIN_CHANGE':
      return {
        ...state,
        chainId: action.data.chainId
      }
    case 'NETWORK_CHANGE':
      return {
        ...state,
        network: action.data.network,
        isNetworkConnected: action.data?.isNetworkConnected
      }
    default:
      return state
  }
}

export default connectWallet
