import './index.css'

import { BaseProvider, createDarkTheme } from 'baseui'
import { persistReducer, persistStore } from 'redux-persist'

import App from './App'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'
import { ToasterContainer } from 'baseui/toast'
import { createStore } from 'redux'
import reportWebVitals from './reportWebVitals'
import rootReducer from './reducers'
import storage from 'redux-persist/lib/storage'

const engine = new Styletron()
const persistConfig = {
  key: 'root',
  storage
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
let store = createStore(persistedReducer)
let persistor = persistStore(store)

const primitives = {
  primaryFontFamily: `'Titillium Web', sans-serif;`
}
const overrides = {
  colors: {
    inputFill: '#111827',
    inputFillActive: '#111827',
    inputBorder: '#111827',
    buttonPrimaryText: '#FFFFFF',
    buttonPrimaryFill: '#1D4ED8',
    buttonPrimaryHover: '#1E3A8A',
    borderFocus: '#111827',
    inputPlaceholder: '#4B5563'
  },
  borders: {
    buttonBorderRadius: '0.75rem'
  }
}

const theme = createDarkTheme(primitives, overrides)

ReactDOM.render(
  <React.StrictMode>
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ToasterContainer autoHideDuration={5000}>
              <App />
            </ToasterContainer>
          </PersistGate>
        </Provider>
      </BaseProvider>
    </StyletronProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
