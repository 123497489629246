export const contracts = {
  BTC: {
    ETH: '',
    BSC: '0x922a05bf1b7e07cf27d3f5fadc8133e00c75b75f',
    MATIC: '0xeB062AD3FE3fDa9cb20e0466E4F023b557Df116B',
    MOONBEAM: '',
    FANTOM: '0x251f6a75192d0003d0ebc7abddc1795354df674e',
    HECO: '',
    BRISE: '0xD73cd54a946e571bDa02028448d1b1083d5723AC'
  },
  MATIC: {
    ETH: '',
    BSC: '0xae35b15e58eaee102fc5c575cea17b8b6ca3dcb6',
    MATIC: '0x9485ADfDcD26F56F9b55cE189905B27845558850',
    MOONBEAM: '',
    FANTOM: '0xEdCd6F462E8a96F596ED33f987D7DA0090E057B0',
    HECO: '',
    BRISE: '0xd4f537755ea6D8DaE3FD56D1919Ce574310E6Ed7'
  },
  ETH: {
    ETH: '',
    BSC: '0xb7141b1a194e9d5e32711917c68fee5db7778e65',
    MATIC: '0x94e152511588e79f6db10e7c879d4bad437107af',
    MOONBEAM: '',
    FANTOM: '0xb06b4ab0d83b6f1a12ff5391daa8fae39a7d5ba2',
    HECO: '',
    BRISE: '0x83F8c01c3De5767E6Ae046575aeeb8B5ab59A107'
  },
  XLM: {
    ETH: '',
    BSC: '0xf3e94c72889afba13ba53898d22717821883e1a5',
    MATIC: '0x03c8fB4716AB826041E6d447c0B3916FEEEFADFE',
    MOONBEAM: '',
    FANTOM: '0xe401744b34f44CeEfCFa2bA66eae9F1E448F0bd6',
    HECO: '',
    BRISE: '0x0f653a1528b9F289009cD6012d5e60392405622C'
  },
  XRP: {
    ETH: '',
    BSC: '0x28cf5786dbc2e9ecc1e5b8fd8a2fce005f095c06',
    MATIC: '0x96729c6De16693D9C9b2013E22842e3eaDcFFE31',
    MOONBEAM: '',
    FANTOM: '0xAAFc50ac5C03555085F555a2B7c139B6EE058CA2',
    HECO: '',
    BRISE: '0x4960eD44e210a022313A32aD84FA6E6e1B70CCd1'
  },
  LTC: {
    ETH: '',
    BSC: '0xa57963d8cb08c157d46862d77be4c0e6b5675494',
    MATIC: '0xF5529E7ff8b0587eD4d243573bC9697A4D12BfBD',
    MOONBEAM: '',
    FANTOM: '0x67dbaA721dA715738011Cc193dB65f0D239004b4',
    HECO: '',
    BRISE: '0xA53e252C5BFb672452A6Ab59f4914Acd8902de13'
  },
  BCH: {
    ETH: '',
    BSC: '0x31ef831ff9f4e4bd88cb3c1f6c6c5d33c89cb6fd',
    MATIC: '0x778C9a3073fac0ED6f2b561109989E23c7AD05A6',
    MOONBEAM: '',
    FANTOM: '0xe296E335643E81fDAab56652DBD3Ef5fF0c64133',
    HECO: '',
    BRISE: '0x81ECaE2F4ba11fabC8eCE3b00500B9AeAC5F2ADa'
  },
  ZEC: {
    ETH: '',
    BSC: '0x941661c8066e0ef6050dcbb84891a77d9db1a20e',
    MATIC: '0x0Dcd7A5DF109057332344Ef6A27C2D68031BBb3B',
    MOONBEAM: '',
    FANTOM: '0x28db80024472D67A0d7249389299dF2b14c0eFeC',
    HECO: '',
    BRISE: '0xF83dD03983596AB51310cd59ea2C0B9865CaE014'
  },
  DOGE: {
    ETH: '',
    BSC: '0x52a86ed7d5bed75c876ec9fd44d259375f623ac0',
    MATIC: '0xe4b2D5b0967758DAe4677C02A7b4440d7251F86D',
    MOONBEAM: '',
    FANTOM: '0xf9AE9267C74cA2d8B50883e7D22D438Bf2878DdC',
    HECO: '',
    BRISE: '0xC0b68855cAcA2921A0Ec50a1990452F211e139fE'
  },
  DASH: {
    ETH: '',
    BSC: '0xb2a04c839b9f91889f333e661c9c51deaa6e642d',
    MATIC: '0x248d0134BA0E931E48c525843a4C0029B20D2956',
    MOONBEAM: '',
    FANTOM: '0xa9A9616d569C38Dd0323A3aED52459D21570DBEd',
    HECO: '',
    BRISE: '0xf4B6614cF14Fbfb21624262Ee77f2B8abd97DD4e'
  },
  BSV: {
    ETH: '',
    BSC: '0x186b614883e57cd31b67b7ae417098aac732010c',
    MATIC: '0x85e773d0Ff19d9Bb00ed0BD0271d72c77C72a2D4',
    MOONBEAM: '',
    FANTOM: '0x8ebF9699406986d5F28fEEa3Be3dd053Ea0F65b9',
    HECO: '',
    BRISE: '0x717fF7cAFBD3d65E4dbF2f4998442BA8fE1BCAc6'
  },
  FRONT: {
    ETH: '',
    BSC: '0x60aa3676582a1369a79ae415122470f245fbc5a8',
    MATIC: '0x2dc1cc99f5E6F72197236fe1e30921EB863E38b5',
    MOONBEAM: '',
    FANTOM: '0x11B3d4F5786487d72F7814395a5455f213609bcf',
    HECO: '',
    BRISE: '0x4EDC3fb8ACaE0fD0eb14a48974c7D774B7388BE7'
  },
  FTM: {
    ETH: '',
    BSC: '0x1d9f90c145df4950a50e7637a8b4066b90727159',
    MATIC: '0x555B1774b6419dC41D917EC47B1f4fBB76e69d68',
    MOONBEAM: '',
    FANTOM: '0x5F3b083B1571f92a67894a989f035BdBf1ee7729',
    HECO: '',
    BRISE: '0x4238f642dDE32A30F39d0E0f9985435FAa6B1593'
  },
  AVAX: {
    ETH: '',
    BSC: '0x54e67c935c5dc9634bcc16f86e6f5747a76b2da4',
    MATIC: '0x005Ed6E17c748BDbDa3262c361A317aA3DbBC2fc',
    MOONBEAM: '',
    FANTOM: '0x89E96B056fb758a8a382454b0bd7ff78d59d6EC7',
    HECO: '',
    BRISE: '0x561A53060F41Ee1BF4A1a0421e93316b7652A41D'
  },
  KFT: {
    ETH: '0xef53462838000184f35f7d991452e5f25110b207',
    BSC: '0x42d096ca4959F9aa548Ee2de1006F48a08d706A0',
    MATIC: '0xC8DDB51cE5002c1984c96926022Ce20B06f11339',
    MOONBEAM: '',
    FANTOM: '0xaEAB17e79C40bFccC477746CE77B661aa724CDfc',
    HECO: '',
    BRISE: '0x814FCDF6FCB8F14e1509f6f6df7E8bCf193b153d'
  },
  USDT: {
    ETH: '',
    BSC: '0x55d398326f99059fF775485246999027B3197955',
    MATIC: '0x7a5e9775D2D78fC0724f704FA7F2a3B0B1cf470E',
    MOONBEAM: '',
    FANTOM: '0xdc5bDD680780cf8B097cA36057E8D8De8ecce2CB',
    HECO: '0xF83dD03983596AB51310cd59ea2C0B9865CaE014',
    BRISE: '0x6BcC59Fe53992B7aa83a1C76cDd539B1C8C6D2c3'
  },
  BRISE: {
    ETH: '',
    BSC: '0x8FFf93E810a2eDaaFc326eDEE51071DA9d398E83',
    MATIC: '0x389fdca2d6e8c625Cb8BEe906339dBd9933863fB',
    MOONBEAM: '',
    FANTOM: '0x2c281AAf99BaA62443802751f1Ab918ed2D83bE5',
    HECO: '0x879225cFCa2FCcfE556b1B1fE278b77dC4256cE6',
    BRISE: '0xEca6AE1D72B2ca644EB2028A964fD55F436263aB'
  },
  BRISE_BSC: {
    ETH: '',
    BSC: '0x8FFf93E810a2eDaaFc326eDEE51071DA9d398E83',
    MATIC: '0x389fdca2d6e8c625Cb8BEe906339dBd9933863fB',
    MOONBEAM: '',
    FANTOM: '0x2c281AAf99BaA62443802751f1Ab918ed2D83bE5',
    HECO: '0x879225cFCa2FCcfE556b1B1fE278b77dC4256cE6',
    BRISE: '0xEca6AE1D72B2ca644EB2028A964fD55F436263aB'
  }
}

export const custodyWallet = '0x5e14128aC1192B31F2f9026D7130F446D0546D9c'
export const stakingContract = {
  address: '0x7A55222ce499e005666e3C36Ec23580D02491c4C',
  tokenPerBlock: 100,
  blockPerDay: 43200,
  blockPerYear: 43200 * 365
}
