const initialState = {
    data: null
}
const connectWallet = (state = initialState, action) => {
    switch (action.type) {
        case 'TRANSACTION_ENTER':
            return {
                ...state,
                data: action.data
            }
        default:
            return state
    }
}

export default connectWallet
